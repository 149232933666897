import React from "react";
import "./styles/theme.scss";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Sidenav from "./components/Sidenav";
import SignIn from "./pages/SignIn";
import NumberServiceUpdater from "./pages/NumberServiceUpdater";
import QueuesInfo from "./pages/QueuesInfo";
import Sounds from "./pages/Sounds";
import Competitions from "./pages/Competitions";
import Admin from "./pages/Admin";
import Agents from "./pages/Agents";

import { RecoilRoot } from "recoil";
import TemporaryOverrides from "./components/temporary-override/TemporaryOverride";
import SecureAssist from "./pages/SecureAssist";
function App() {

    console.log("Mode:",process.env.REACT_APP_ENV_NAME.toLowerCase())
    return (
        <BrowserRouter>
            <Switch>
                <RecoilRoot>
                    <Route path="/login" exact component={SignIn} />
                    <Route path="/">
                        <Sidenav />
                        <Route path="/" exact component={Dashboard} />
                        <Route path="/queues" exact component={QueuesInfo} />
                        <Route
                            path="/admin/numberServiceUpdater"
                            exact
                            component={NumberServiceUpdater}
                        />
                        <Route path="/sounds" exact component={Sounds} />
                        <Route
                            path="/competitions"
                            exact
                            component={Competitions}
                        />
                        <Route
                            path="/temporary-routing"
                            exact
                            component={TemporaryOverrides}
                        />
                        <Route path="/agents" exact component={Agents} />
                        <Route path="/admin" exact component={Admin} />
                        <Route path="/secureassist" exact component={SecureAssist} />
                    </Route>
                </RecoilRoot>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
