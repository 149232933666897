import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StatsPage from "../components/common/StatsPage";
import { selectSelectedCustomer } from "../rdx/slices/customerSlice";
import {
    getInboundCallsData,
    clearActiveCallData,
} from "../rdx/slices/activeCallsSlice";
import LiveCallTable from "../components/home/table/LiveCallsTable";
import TopCards from "../components/home/cards/TopCards";
import { getWhoAmI } from "../rdx/slices/authSlice";


const Dashboard = () => {
    const dispatch = useDispatch();
	const selectedCustomer = useSelector(selectSelectedCustomer);

    useEffect(() => {
        dispatch(getWhoAmI());
    }, []);

    /**
     * This UseEffect listens for a change in the selected customer and re-creates an interval timer that
     * calls the getInboundCallsData thunk in the activeCallsSlice to fetch the active calls of the selected customer.
     * The "Inbound Live Calls" card and the Live Inbound Calls table will both observe this data to display
     *  A) Count of calls  - The Inbound Calls Card.
     *  B)Table of calls. - The Inbound Calls Table.
     */
    useEffect(() => {
        let getActiveCallsInterval = null;
        if (selectedCustomer) {
            dispatch(clearActiveCallData());
            dispatch(getInboundCallsData(selectedCustomer.id));
            getActiveCallsInterval = setInterval(() => {
                dispatch(getInboundCallsData(selectedCustomer.id));
            }, 2000);
        }
        //Clears the interval.
        return () => {
            clearInterval(getActiveCallsInterval);
        };
    }, [selectedCustomer]);

    const cards = [
        {
            field: "activeCalls",
            title: "ACTIVE INBOUND CALLS",
            tag: "LIVE",
            icon: "fe fe-phone-incoming",
        },
        {
            field: "totalInboundCalls",
            title: "TOTAL INBOUND CALLS",
            tag: "",
            icon: "fe fe-phone-call",
        },
        {
            field: "totalInboundMinutes",
            title: "TOTAL INBOUND MINUTES",
            tag: "",
            icon: "fe fe-bar-chart-2",
        },
        {
            field: "missedCalls",
            title: "MISSED CALLS",
            tag: "",
            icon: "fe fe-phone-missed",
        },
    ];

    const render = selectedCustomer ? (
        <div>
            <TopCards
                cards={cards}
                url={`/dashboard/customers/${selectedCustomer.id}/cards`}
                timeInterval={5000}
            />
            <LiveCallTable customerId={selectedCustomer.id} />
        </div>
    ) : null;

    return <StatsPage title="Dashboard" pretitle="Overview" render={render} />;
};

export default Dashboard;
